<template>
  <div class="user">
    <div class="search">
      <el-select v-model="value" placeholder="请选择">
        <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-input type="text" placeholder="请输入内容" v-model="searchValue"></el-input>
      <div class="types">
        <el-select v-model="typeValue" placeholder="文件类型">
          <el-option v-for="item in typeOptions" :key="item.label" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button @click="handleReast">
        <span>重置</span>
      </el-button>
      <el-button type="primary" @click="handleSearch">
        <span>查询</span>
      </el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" border
      :header-cell-style="{ 'text-align': 'center', background: '#f2f2f2' }" :cell-style="{ 'text-align': 'center' }">
      <el-table-column label="文件名称" width="180">
        <template slot-scope="scope">
          <div class="fileName">
            <img :src="scope.row.fileType == 6 ? ' https://oss.sccpy.com/sccpy/images/hash.png' :
              scope.row.presType == 104 ? scope.row.preservationFile
                : scope.row.presType == 101 ? ' https://oss.sccpy.com/sccpy/images/file.png'
                  : scope.row.presType == 103 ? ' https://oss.sccpy.com/sccpy/images/audio.png'
                    : scope.row.presType == 102 ? ' https://oss.sccpy.com/sccpy/images/video.png'
                      : ' https://oss.sccpy.com/sccpy/images/video.png'" alt="">
            <span style="margin-left: 10px">{{ scope.row.presTitle }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="确权时间">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="文件类型">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ Type[scope.row.presType] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="确权类型">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.cpyType == 1 ? '文件确权' : '哈希确权' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建人">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.presEntityName }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="10"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [{
        value: 1,
        label: '文件名称'
      }, {
        value: 2,
        label: '创建人'
      }, {
        value: 3,
        label: '白名单'
      }],
      typeOptions: [
        {
          value: 104,
          label: '绘画'
        }, {
          value: 101,
          label: '文字'
        }, {
          value: 103,
          label: '音频'
        }, {
          value: 102,
          label: '摄影'
        }, {
          value: 199,
          label: '视频'
        }
      ],
      typeValue: '',
      value: 1,
      tableData: [],
      currentPage: 1,
      total: 0,
      Type: {
        104: '绘画',
        101: '文字',
        103: '音频',
        199: '视频',
        102: '摄影',
      },
      searchValue: '', // 搜索值
    }
  },
  methods: {
    // 重置
    handleReast() {
      this.value = 1
      this.typeValue = ''
      this.searchValue = ''
      this.getList()
    },
    // 查询
    handleSearch() {
      this.getList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getList()
    },
    // 确权列表
    getList() {
      let params = {
        current: this.currentPage,
        pageSize: 10,
        presType: this.typeValue
      }
      if (this.value == 1) {
        params.presTitle = this.searchValue
      } else {
        params.presEntityName = this.searchValue
      }
      this.$axiosGet('/cpyApplyManage/queryPage', params).then(res => {
        console.log(res);
        this.tableData = res.data.records
        this.total = res.data.total
      })
    }
  },
  created() {
    this.getList()
  },
}
</script>

<style lang="less" scoped>
.search {
  display: flex;
  align-items: center;
  margin-bottom: 38px;

  .el-input {
    width: 200px;
    margin: 0 10px;
  }

  .types {
    margin-right: 10px;
  }
}

.pagination {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
}

.el-table {
  .fileName {
    display: flex;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      background: #ccc;
    }
  }

}

.el-button {
  padding: 10px 20px;
}
</style>
